import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import {get, post } from '@/utils/request'
import _ from 'lodash'
import config from "@/config/config"
Vue.prototype._ = _

//引入iview框架
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);

//引入字体图标
import '@/assets/font/myFont.css';

//引入dataV组件
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
// Vue.mixin({
//   methods:{
//     addMarker(lon,lat,name){
//       if(window.mapObj){
//         if(window.marker){
//           window.marker.remove()
//           window.marker = null
//         }
//           window.mapObj.setZoomAndCenter(14, [lon, lat])
//           window.marker = new AMap.Marker({
//             name: "测试点",
//             map: window.mapObj,
//             position: new AMap.LngLat(lon, lat),
//             content: "<div class='marker'></div><span class='marker-text'>"+name+"</span>",
            
//           });
//           console.log(marker);
//       }
     
//     }
//   }
// })
window.addMarker = function(lon,lat,name,sampleCount=0,unqualifiedNumber=0){
  if(window.mapObj){
    // if(window.marker){
    //   window.marker.remove()
    //   window.marker = null
    // }
    if(!window.marker){
      window.marker = []
    }
    //
      const marker = new AMap.Marker({
        name: "",
        map: window.mapObj,
        position: new AMap.LngLat(lon, lat),
        content: "<div class='marker'></div><span class='marker-text'>"+name+"</span><div class='marker-desc'>采样数:"+sampleCount+" 不合格数:"+unqualifiedNumber+"</div>",
        
      })
      marker.hide()
      window.marker.push(marker)
  }
}

window.moveTo = function(lon,lat){
  window.mapObj.setZoomAndCenter(18, [lon, lat])
  
}
Vue.config.productionTip = false
Vue.prototype.$ajax = axios;
Vue.prototype.$post = post;
Vue.prototype.$get = get;
document.title = config.COMPANY_NAME
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
